import React, { Component } from 'react';
import { withCookies } from 'react-cookie';
import { Header, Form, Icon, Grid, Image, Button, Menu } from 'semantic-ui-react';
import { Container, Row, Col, } from "reactstrap";

import ColorNavbar from "components/client/ClientNavbar/ColorNavbarTest.js";

// import { allSiteObj } from 'routes/official/CustomMenu.js';
import FooterGray from 'components/Footers/FooterGray.js';
import {
  Contact1, Blog1, Blog1a, Blog4,
  Feature1, Card1, Comment1,
  Section1, Section2, Section3
} from 'components/client/SectionLayout/SectionLayout.js';
import WhiteNavbar from 'components/client/MiddleNavbar/MiddleNavbar.js';

import Parse from 'widget/parse'
import IwGallery from 'widget/IwGallery.js';
import MyDimmer from '../../layouts/Dimmer';
import { list_to_tree } from '../../widget/mylib/tree';

const showSectionLayout = ({ layout, index, ...data }) => {
  switch (layout) {
    case 'Blog1':
      return <Blog1 key={`section${layout}${index}`} index={index} {...data} />
    case 'Blog1a':
      return <Blog1a key={`section${layout}${index}`} index={index} {...data} />
    case 'Blog4':
      return <Blog4 key={`section${layout}${index}`} index={index} {...data} />
    // case 'Contact1':
    //   return <Contact1 key={`section${layout}${index}`} index={index} {...data} />
    case 'Feature1':
      return <Feature1 key={`section${layout}${index}`} index={index} {...data} />
    case 'Card1':
      return <Card1 key={`section${layout}${index}`} index={index} {...data} />
    case 'Section1':
      return <Section1 key={`section${layout}${index}`} index={index} {...data} />
    case 'Section2':
      return <Section2 key={`section${layout}${index}`} index={index} {...data} />
    case 'Section3':
      return <Section3 key={`section${layout}${index}`} index={index} {...data} />
    case 'Comment1':
      return <Comment1 key={`section${layout}${index}`} index={index} {...data} />
    case 'Gallery':
      return <IwGallery key={`section${layout}${index}`} index={index} {...data} />

    default:
      return null
  }
}

const showSectionLayout2 = ({ layout, index, ...data }) => {
  // console.log('2')
  switch (layout) {
    case 'Contact1':
      return <Contact1 key={`section${layout}${index}`} index={index} {...data} />

    default:
      return null
  }
}

class OfficialTest extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tree: [],
      list: [],
    };
  }

  componentDidMount = async () => {
    // const { companyObj } = this.props;
    // const companyId = companyObj.objectId;

    this.handleFetch()
  };

  componentWillReceiveProps(nextProps) {
    this.handleFetch(nextProps);
  }

  handleFetch = async () => {
    const { companyObj } = this.props;
    // const { obj } = this.state;
    const companyId = companyObj.objectId;
    const type = 'webpage'
    const data = await Parse.queryData('treeData', { companyId, type }, { orderBy: 'orderId,asc' })

    const list = data
      .filter(item => !item.hide)
      .map(item => ({ ...item, href: `${item.href || item.objectId}` }))
    const tree = list_to_tree(list)

    this.setState({ list, tree, loading: false })
  };

  render() {
    const { companyObj, params } = this.props
    const { image, loading, tree, list } = this.state;
    const { pageId = '' } = params

    // const hash = global.window && global.window.location.hash // 有含#
    console.log('pageId', pageId)
    const contentObjDef = { titleImg: '', title: '', title2: '', children: [], content: [], showArr: [] };
    let contentObj = {};
    let siblingArr = [];
    let childrenArr = [];

    // if (!hash) { // 首頁
    if (!pageId) { // 首頁
      contentObj = list.find(item => item.href === '#.') || contentObjDef
      contentObj = { ...contentObj, title: companyObj.name }
      childrenArr = list.filter(item => item.href !== '#.' && !item.parentId) || []
      // console.log(childrenArr)
    } else {
      contentObj = list.find(item => item.href === pageId) || contentObjDef
      siblingArr = list.filter(item => item.parentId === contentObj.parentId) || []
      childrenArr = list.filter(item => item.parentId === contentObj.objectId) || []
    }

    if (!contentObj.title) {
      contentObj = { href: '#404', title: '404', title2: '找不到頁面', titleImg: 'https://images.unsplash.com/photo-1473798349978-d18a8c8dbf41?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=2250&q=80' }

    }
    const { href, imgPre = '', title = '', title2 = '', content = [], content2 = [], showArr = [] } = contentObj;
    // console.log('contentObj', contentObj)

    // const menuSetting = {}// allSiteObj[companyObj.objectId].menuSetting;
    // const subMenu = [];
    const subMenu = childrenArr.length ? childrenArr : (siblingArr || []);

    // console.log(tree)
    // console.log(list)
    // console.log('childrenArr', childrenArr)
    // console.log('children.length', children.length)
    // console.log('siblingArr', siblingArr)
    // console.log('subMenu', subMenu)
    // console.log('href', href)
    console.log('showArr', showArr)
    return (<>
      {loading ? <MyDimmer /> : <>
        <ColorNavbar {...this.props} tree={tree}
        // menuSetting={menuSetting}
        />
        {title ? <div
          className="page-header page-header-small settings-background"
          style={{ backgroundImage: "url(" + imgPre + ")", }}
        >
          <div className="filter" />
          <div className="content-center">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8" style={{ backgroundColor: 'rgb(40 40 40 / 40%)', paddingTop: '16px', paddingBottom: title2 ? '0px' : '16px' }}>
                  <Header as='h2' inverted>{title}</Header>
                  {title2 ? <h5 className="description" style={{ paddingBottom: '15px' }}>{title2}</h5> : null}
                </Col>
              </Row>
            </Container>
          </div>
        </div> : null}
        {href !== '#.' && subMenu && subMenu.length ? <WhiteNavbar arr={subMenu}
        //  menuSetting={menuSetting}
        /> : null}
        <div className="main">
          <div className="section">
            <Container>
              {childrenArr.length && subMenu && subMenu.length ? <Blog4 arr={subMenu} /> : null}
              {showArr.map((item, index) => showSectionLayout({ ...item, index }))}
            </Container>
          </div>
        </div>
        {showArr.map((item, index) => showSectionLayout2({ ...item, index }))}
        {/* <div style={{ top: '60%', position: 'sticky', zIndex: 99, float: 'right' }}>
            <Button icon={<Icon name='linechat' size='large' />} color='green' size='massive' circular />
            <br />
            <br />
            <Button icon={<Icon name='facebook messenger' size='large' />} color='blue' size='massive' circular />
          </div>
          <br /> */}
        <FooterGray />
      </>}
    </>);
  }
}
export default withCookies(OfficialTest);
