import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

// import Parse from 'widget/parse';
// import AiasoOfficial from './AiasoOfficial';
// import SampleOfficial from './SampleOfficial';
// import Set1 from './registering/set1'
// import Set2 from './registering/set2'

class Official extends Component {
  constructor(props) {
    super(props);
    this.state = {
      companyObj: this.props.companyObj
    };
  }

  componentDidMount = async () => {
  };

  componentWillReceiveProps(nextProps) {
  }

  handleFetch = async () => {
    const { companyObj } = this.state;
    // const { obj } = this.state;
    // const companyId = companyObj.objectId;

    // const webnavArr = await Parse.queryData('webnav', { companyId });
    // this.setState({ webnavArr });

    // const webnavLevel2Arr = await Parse.queryData('webnav', { companyId, level: '2' });
    // this.setState({ webnavLevel2Arr });
    // obj.text &&
    //   obj.text.forEach(item => {
    //     const text = document.getElementById(`new${item.title}`);
    //     text.innerHTML = item[item.title];
    //   });
    // if (obj.contact && obj.contact.newMap) {
    //   const map = document.getElementById('mapBox');
    //   map.innerHTML = obj.contact.newMap;
    // }
    // this.setState({ loading: false });
    // const data = await Parse.queryData('webpage', { companyId: companyObj.objectId })
    // const webpageArr = data.sort((a, b) => a.order - b.order)
    // this.setState({ webpageArr })

    // const data3 = await Parse.queryData('webimage', { companyId: companyObj.objectId })
    // this.setState({ webimageArr: data3 })

    // const data4 = await Parse.queryData('announceCenter', { companyId });
    // this.setState({ announceCenterArr: data4 })

    // const footerObj = webnavArr.find(item => item.pageType === '4') || {}
    // const footerArr = webpageArr.filter(item => item.pageId === footerObj.objectId).sort((a, b) => a.order - b.order)
    // this.setState({ footerArr, loading: false })
  };

  render() {
    // const { companyObj, } = this.state;
    //////可以根據companyObj裡頭的數據，判斷何種類型套版，撈取在資料庫內的圖片呈現
    return (<>
      {/* <Set1 {...this.props} /> */}
      {/* {companyObj.objectId === 'Wuwrcczn0Z' ? <><AiasoOfficial  {...this.props} /></> : <><SampleOfficial {...this.props} /></>} */}
      {/* {companyObj.objectId === 'Wuwrcczn0Z' ? <><AiasoOfficial  {...this.props} /></> : null} */}
      {/* {companyObj.objectId === 'Wuwrcczn0Z' ? <><Set2  {...this.props} /></> : null} */}
      {/* <Set1 {...this.props} /> */}
      {/* <Set2 {...this.props} /> */}
    </>);
  }
}

export default withCookies(Official);
