
export const list_to_tree = (list) => {
  let map = {}, node, roots = [];

  for (let i = 0; i < list.length; i += 1) {
    map[list[i].objectId] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (let i = 0; i < list.length; i += 1) {
    node = list[i];
    // if (node.parentId !== "0") {
    if (node.parentId) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
}

const flatten = (children = [], extractChildren, level = 1, parentId = '') => Array.prototype.concat.apply(
  children.map((x, orderId) => ({ ...x, level, parentId, orderId })),
  children.map(x => flatten(extractChildren(x) || [], extractChildren, (level || 1) + 1, x.objectId))
);

const extractChildren = x => x.children;

// tree to list
export const flat = (treeStructure) => ([...flatten(treeStructure, extractChildren).map(x => delete x.children && x)]);
