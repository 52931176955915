import React, { Component } from 'react';
import { withCookies } from 'react-cookie';

import Parse from 'widget/parse';
import ColorNavbar from 'components/Navbars/ColorNavbar';
import WhiteNavbar from 'components/Navbars/WhiteNavbar';
import MyDimmer from '../../layouts/Dimmer';
import WebContent from './WebContent.jsx';

class OfficialBoBee extends Component {
  constructor(props) {
    super(props);
    this.state = {
      collapse: true,
      photo1: '',
      text: '',
      obj: {
        photo: [],
        text: [],
        background: [],
        button: [],
        social: [],
        share: [],
        contact: {},
      },
      webnavArr: [],
      webnavLevel2Arr: [],
      webpageArr: [],
      webimageArr: [],
      footerArr: [],
      announceCenterArr: [],
      companies: [],
      loading: true,
      companyObj: this.props.companyObj
    };
  }

  componentDidMount = async () => {
    const { companyObj } = this.state;
    // const companyId = companyObj.objectId;
    // const albumArr = await Parse.queryData('webimage', {
    //   companyId,
    //   componentId: 'StartUpEdit',
    //   group: 'album',
    // });
    // const teamArr = await Parse.queryData('webimage', {
    //   companyId,
    //   componentId: 'StartUpEdit',
    //   group: 'teamLeader',
    // });
    // const obj = await Parse.findDoc('webpage', {
    //   companyId,
    //   componentId: 'StartUpEdit',
    // });
    const companies = await Parse.queryData('company')
    this.setState({
      // albumArr, teamArr, obj,
      companies
    }, () => this.handleFetch());
  };

  componentWillReceiveProps(nextProps) {
  }

  handleFetch = async () => {
    const { companyObj } = this.state;
    const { obj } = this.state;
    const companyId = companyObj.objectId;

    const webnavArr = await Parse.queryData('webnav', { companyId });

    // const webnavLevel2Arr = await Parse.queryData('webnav', { companyId, level: '2' });
    // obj.text && obj.text.forEach(item => {
    //   const text = document.getElementById(`new${item.title}`);
    //   text.innerHTML = item[item.title];
    // });

    // if (obj.contact && obj.contact.newMap) {
    //   const map = document.getElementById('mapBox');
    //   map.innerHTML = obj.contact.newMap;
    // }
    const data = await Parse.queryData('webpage', { companyId: companyObj.objectId })
    const webpageArr = data.sort((a, b) => a.order - b.order)

    // const data3 = await Parse.queryData('webimage', { companyId: companyObj.objectId })

    // const data4 = await Parse.queryData('announceCenter', { companyId });

    // const footerObj = webnavArr.find(item => item.pageType === '4') || {}
    // const footerArr = webpageArr.filter(item => item.pageId === footerObj.objectId).sort((a, b) => a.order - b.order)
    this.setState({ webnavArr, webpageArr, loading: false });
    // this.setState({ webnavArr, webnavLevel2Arr, webpageArr, webimageArr: data3, announceCenterArr: data4, footerArr, loading: false })
  };

  changeCompany = async id => {
    const companies = await Parse.queryData('company');
    const companyObj = companies.find(item => item.objectId === id);
    this.setCompany(companyObj);
  };

  setCompany = async (companyObj) => {
    const { cookies } = this.props;
    // const dbLang = cookies.get('dbLang') || 'name';
    cookies.set('comp', companyObj.objectId, { path: '/' });
    let semesterYear = '';
    let semester = '';
    // if (companyObj.objectId === 'nsgdMpkSq4') {
    //   const data = await Parse.queryData('aiasoUnits', {
    //     companyId: companyObj.objectId,
    //   });
    //   semesterYear = data[0].currentSemester.substring(0, 4);
    //   semester = data[0].currentSemester.substring(5);
    // }
    this.setState({ companyObj, semesterYear, semester });
  };


  render() {
    const { webpageArr, obj, loading, webnavArr, companies, companyObj, webimageArr, announceCenterArr, pageObj, webnavLevel2Arr, footerArr } = this.state;
    return (<>
      {/* {loading && !obj.objectId ? ( */}
      {loading ? (<MyDimmer />) : <>
        <WhiteNavbar {...this.props} companyObj={companyObj} webnavArr={webnavArr} webpageArr={webpageArr} companies={companies} changeCompany={this.changeCompany} />
        {webnavLevel2Arr.map(item => <>
          <div
            key={"sec" + item.objectId} id={item.objectId}
            className={"section section-" + item.objectId + " cd-section"}
            style={{ padding: '0 0' }}
          ></div>
          {webpageArr.filter(item2 => item2.pageId === item.objectId).map(item2 =>
            <div
              key={"test" + item2.objectId}
              id={item2.objectId}
            >
              <WebContent {...this.props} form={item2} webimageArr={webimageArr} announceCenterArr={announceCenterArr} />
            </div>
          )}
          <br />
          <br />
        </>)}
        {footerArr.map(item => <WebContent key={item.objectId} {...this.props} pageObj={pageObj} form={item} id={item.objectId} />)}
      </>}
    </>);
  }
}

export default withCookies(OfficialBoBee);
