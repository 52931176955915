import React from 'react';

import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,

  Input,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Form,
  FormGroup,
  UncontrolledTooltip,
  Badge,
  Label,
} from 'reactstrap';
const bs4Color = ['warning', 'info', 'danger', 'success']

export const Template = () => (<></>)
export const Section3 = () => (<>
  Section3<br />
  <Row>
    <Col className="ml-auto mr-auto text-center" md="6">
      <div className="space-top" />
      <h2 className="title">Pick the best plan for you</h2>
      <h5 className="description">
        You have Free Unlimited Updates and Premium Support on each
        package.
      </h5>
    </Col>
  </Row>
</>)

export const Blog4 = ({ arr }) => (<>
  <Row>
    {arr.map(({ href, title, title2, imgPre }, i) => <Col md="6" key={`blog4${i}`}>
      <Card className="card-plain card-blog text-center">
        <div className="card-image card-img-top">
          <a href={href}
          // onClick={(e) => e.preventDefault()}
          >
            <img
              alt="..."
              className="img img-raised"
              src={imgPre}
            />
          </a>
        </div>
        <CardBody>
          <h3 className={`card-category text-${bs4Color[i % 4]}`}>
            {title}
          </h3>
          <CardTitle tag="h3">
            <a href={href}
            // onClick={(e) => e.preventDefault()}
            >
              {/* Learning different cultures through travel */}
              {title2}
            </a>
          </CardTitle>
          {/* <p className="card-description">
            {title2}
          </p> */}
          {/* <br />
          <Button
            className="btn-round"
            color="warning"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            查看更多
          </Button> */}
        </CardBody>
      </Card>
    </Col>)}
  </Row>
</>)

export const Contact1 = ({ bgImg }) => (<>
  {/* Contact1<br /> */}
  <div
    className="contactus-1 section-image"
    style={{ backgroundImage: "url(" + bgImg + ")", }}
  >
    <Container>
      <Row>
        <Col className="ml-auto mr-auto" md="10">
          <Card className="card-contact no-transition">
            <CardTitle className="text-center" tag="h3">
              聯絡我們
            </CardTitle>
            <Row>
              <Col className="ml-auto" md="5">
                <CardBody>
                  {/* <div className="info info-horizontal">
                    <div className="icon icon-info">
                      <i className="nc-icon nc-pin-3" />
                    </div>
                    <div className="description">
                      <h4 className="info-title">
                        Find us at the office
                    </h4>
                      <p>
                        Bld Mihail Kogalniceanu, nr. 8, <br />
                      7652 Bucharest, <br />
                      Romania
                    </p>
                    </div>
                  </div> */}
                  <div className="info info-horizontal">
                    <div className="icon icon-danger">
                      <i className="nc-icon nc-badge" />
                    </div>
                    <div className="description">
                      <h4
                      // className="info-title"
                      >聯絡資訊</h4>
                      <p>
                        資宸科技有限公司 <br />
                        +886 3 437 1188<br />
                        Mon - Fri, 9:00-18:00
                      </p>
                    </div>
                  </div>
                </CardBody>
              </Col>
              <Col className="mr-auto" md="5">
                <Form id="contact-form" method="post" role="form">
                  <CardBody>
                    <Row>
                      <Col md="6">
                        <FormGroup className="label-floating">
                          <label className="control-label">
                            姓名
                          </label>
                          <Input
                            name="name"
                            placeholder="姓名"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup className="label-floating">
                          <label className="control-label">
                            手機
                          </label>
                          <Input
                            name="name"
                            placeholder="手機"
                            type="text"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <FormGroup className="label-floating">
                      <label className="control-label">
                        Email
                      </label>
                      <Input
                        name="email"
                        placeholder="Email"
                        type="email"
                      />
                    </FormGroup>
                    <FormGroup className="label-floating">
                      <label className="control-label">
                        訊息
                      </label>
                      <Input
                        id="message"
                        name="message"
                        placeholder="您的寶貴意見，我們將儘快與您連絡"
                        type="textarea"
                        rows="6"
                      />
                    </FormGroup>
                    <Row>
                      <Col md="6">
                        {/* <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox" />
                          I'm not a robot !{" "}
                            <span className="form-check-sign" />
                          </Label>
                        </FormGroup> */}
                      </Col>
                      <Col md="6">
                        <Button
                          className="pull-right"
                          color="primary"
                          type="submit"
                        >
                          送出訊息
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Form>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  </div>
</>)

export const Blog1 = ({ title1 = '', title2 = '', info = '', img = '', title1Color = '', index = 0 }) => (<>
  {/* Blog1<br /> */}
  <Row>
    <Col className="ml-auto mr-auto" md="10">
      {/* <h2 className="title">Latest Blogposts</h2> */}
      {/* <br /> */}
      <Card className="card-plain card-blog">
        <Row>
          <Col md="5">
            <div className="card-image">
              <img alt={title1} className="img" src={img} />
            </div>
          </Col>
          <Col md="7">
            <CardBody>
              <h6 className={`card-category text-${title1Color || bs4Color[index % 4]}`}>{title1}</h6>
              <CardTitle tag="h3">
                {/* <a href="#pablo" onClick={(e) => e.preventDefault()}> */}
                {title2}
                {/* </a> */}
              </CardTitle>
              <p className="card-description">
                {info}
                {/* {" "} */}
                {/* <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Read More
              </a> */}
              </p>
              {/* <p className="author">
                by{" "}
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <b>Tim Merel</b>
                </a>
              , 11 hours ago
            </p> */}
            </CardBody>
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>
</>)

export const Blog1a = ({ title1 = '', title2 = '', info = '', img = '', title1Color = '', index = 0 }) => (<>
  <Row>
    <Col className="ml-auto mr-auto" md="10">
      {/* <h2 className="title">Latest Blogposts</h2> */}
      {/* <br /> */}

      <Card className="card-plain card-blog">
        <Row>
          <Col md="7">
            <CardBody>
              <h6 className="card-category text-danger">
                <i className="fa fa-free-code-camp mr-1" />
                Trending
              </h6>
              <CardTitle tag="h3">
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Okoli connects you with tour guides around the world
                </a>
              </CardTitle>
              <p className="card-description">
                Okoli is a new service by developer Jack Deneut that
                aims to replace those ugly little cards you find in
                tourist hotels. The web app, which currently works in
                Prague, Budapest, Berlin and Paris, lets you reserve a
                nearly private tour with a professional guid — and…{" "}
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  Read More
                </a>
              </p>
              <p className="author">
                by{" "}
                <a href="#pablo" onClick={(e) => e.preventDefault()}>
                  <b>John Biggs</b>
                </a>
                , 3 seconds ago
              </p>
            </CardBody>
          </Col>
          <Col md="5">
            <div className="card-image">
              <img
                alt="..."
                className="img"
              // src={
              //   require("assets/img/sections/federico-beccari.jpg")
              //     .default
              // }
              />
            </div>
          </Col>
        </Row>
      </Card>
    </Col>
  </Row>
</>)

export const Feature1 = ({ img }) => (<>
  {/* Feature1<br /> */}
  <Row>
    <Col md="6">
      <div className="ipad-container">
        <img alt="..." src={img} />
      </div>
    </Col>
    <Col className="offset-1" md="4">
      <div className="info info-horizontal">
        IFACE 3S
        <div className="icon icon-info">
          <i aria-hidden={true} className="nc-icon nc-atom" />
        </div>
        <div className="description">
          <h4 className="info-title">產品特色</h4>
          <p>人臉測溫、刷臉開門、戴口罩可識別</p>
        </div>
      </div>
      <div className="info info-horizontal">
        <div className="icon icon-success">
          <i aria-hidden={true} className="nc-icon nc-ruler-pencil" />
        </div>
        <div className="description">
          <h4 className="info-title">管理後台</h4>
          <p>即時監控、遠端控制、點名簽到、高溫通報、無須安裝</p>
        </div>
      </div>
      <div className="info info-horizontal">
        <div className="icon icon-danger">
          <i aria-hidden={true} className="nc-icon nc-tie-bow" />
        </div>
        <div className="description">
          <h4 className="info-title">產品規格</h4>
          <p>多國語言、WIFI連線、串接門禁系統</p>
        </div>
      </div>
    </Col>
  </Row>
</>)

export const Card1 = () => (<>
  Card1<br />
  <h4 className="title">Blog cards</h4>
  <Row>
    <Col md="4" sm="6">
      <Card className="card-blog">
        <div className="card-image">
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="img"
            // src={
            //   require("assets/img/sections/neill-kumar.jpg").default
            // }
            />
          </a>
        </div>
        <CardBody className="text-center">
          <CardTitle tag="h4">
            We will create a great experience
          </CardTitle>
          <div className="card-description">
            Cards are an interaction model that are spreading pretty
            widely, in fact. What all of these have in common is that
            they're pulling...
          </div>
          <CardFooter>
            <Button
              className="btn-round"
              color="danger"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              View Article
            </Button>
          </CardFooter>
        </CardBody>
      </Card>
      <Card data-color="purple">
        <CardBody className="text-center">
          <h6 className="card-category">
            <i className="fa fa-dribbble mr-1" />
            Dribbble
          </h6>
          <CardTitle tag="h5">
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              "Good Design Is as Little Design as Possible"
            </a>
          </CardTitle>
          <p className="card-description">
            Design makes an important contribution to the preservation
            of the environment. It conserves resources and minimises
            physical and visual pollution throughout the lifecycle of
            the product....
          </p>
          <CardFooter className="text-center">
            <Button
              className="btn-round btn-just-icon mr-1"
              color="neutral"
              href="#pablo"
              outline
              id="tooltip275070155"
              onClick={(e) => e.preventDefault()}
            >
              <i className="fa fa-bookmark-o" />
            </Button>
            <UncontrolledTooltip delay={0} target="tooltip275070155">
              Bookmark
            </UncontrolledTooltip>
            <Button
              className="btn-neutral btn-round"
              color="default"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <i className="fa fa-newspaper-o mr-1" />
              Read
            </Button>
          </CardFooter>
        </CardBody>
      </Card>
      <Card>
        <CardBody>
          <h6 className="author pull-left">Ronna Johnson</h6>
          <span className="category-social text-info pull-right">
            <i className="fa fa-twitter" />
          </span>
          <div className="clearfix" />
          <p className="card-description">
            {'"'}It clarifies the product's structure. Better still, it
            can make the product clearly express its function by making
            use of the{" "}
            <a
              className="text-danger"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              @mike
            </a>
            {"'"}s intuition. At best, it is self-explanatory.{'"'}
          </p>
        </CardBody>
      </Card>
    </Col>
    <Col md="4" sm="6">
      <Card className="card-blog">
        <div className="card-image">
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="img"
            // src={
            //   require("assets/img/sections/anders-jilden.jpg").default
            // }
            />
          </a>
        </div>
        <CardBody>
          <CardTitle tag="h4">Good Design Is Aesthetic</CardTitle>
          <div className="card-description">
            Cards are an interaction model that are spreading pretty
            widely, in fact. What all of these have in common is that
            they're pulling...
          </div>
          <hr />
          <CardFooter>
            <div className="author">
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img
                  alt="..."
                  className="avatar img-raised mr-2"
                // src={
                //   require("assets/img/faces/ayo-ogunseinde-2.jpg")
                //     .default
                // }
                />
                <span>Mike John</span>
              </a>
            </div>
            <div className="stats">
              <i className="fa fa-clock-o" /> 5 min read
            </div>
          </CardFooter>
        </CardBody>
      </Card>
      <Card
        data-background="image"
        style={{
          // backgroundImage:
          // "url(" +
          // require("assets/img/sections/rawpixel-com.jpg").default +
          // ")",
        }}
      >
        <CardBody>
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <CardTitle tag="h3">
              Ten Principles of {"“Good Design”"}
            </CardTitle>
          </a>
          <p className="card-description">
            Don't be scared of the truth because we need to restart the
            human foundation in truth And I love you like Kanye loves
            Kanye I love Rick Owens{"’ "}bed design but the back is...
          </p>
          <CardFooter>
            <Button
              className="btn-round"
              color="success"
              href="#pablo"
              onClick={(e) => e.preventDefault()}
            >
              <i className="fa fa-file-pdf-o mr-1" />
              Download PDF
            </Button>
          </CardFooter>
        </CardBody>
      </Card>
    </Col>
    <Col md="4" sm="6">
      <Card data-color="orange">
        <CardBody>
          <div className="author">
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img
                alt="..."
                className="avatar img-raised mr-2"
              // src={
              //   require("assets/img/faces/erik-lucatero-2.jpg")
              //     .default
              // }
              />
              <span>Erik Johnson</span>
            </a>
          </div>
          <span className="category-social pull-right">
            <i className="fa fa-quote-right" />
          </span>
          <div className="clearfix" />
          <p className="card-description">
            {'"'}Less, but better – because it concentrates on the
            essential aspects, and the products are not burdened with
            non-essentials. Back to purity, back to simplicity. At best,
            it is self-explanatory.{'"'}
          </p>
        </CardBody>
      </Card>
      <Card>
        <CardBody className="text-center">
          <span className="category-social text-info pull-right">
            <i className="fa fa-twitter" />
          </span>
          <div className="clearfix" />
          <div className="author">
            <a href="#pablo" onClick={(e) => e.preventDefault()}>
              <img
                alt="..."
                className="avatar-big img-raised border-gray"
              // src={
              //   require("assets/img/faces/kaci-baum-2.jpg").default
              // }
              />
            </a>
            <CardTitle tag="h5">Kaci Baum</CardTitle>
            <p className="category">
              <a
                className="text-danger"
                href="#pablo"
                onClick={(e) => e.preventDefault()}
              >
                @kacibaum
              </a>
            </p>
          </div>
          <p className="card-description">
            {'"'}Less, but better – because it concentrates on the
            essential aspects, and the products are not burdened with
            non-essentials.{'"'}
          </p>
        </CardBody>
      </Card>
      <Card>
        <div className="card-image">
          <a href="#pablo" onClick={(e) => e.preventDefault()}>
            <img
              alt="..."
              className="img"
            // src={
            //   require("assets/img/sections/david-marcu.jpg").default
            // }
            />
          </a>
        </div>
        <CardBody>
          <Badge color="warning" pill>
            Travel
          </Badge>
          <CardTitle tag="h5">
            Currently at the San Francisco Museum of Modern Art
          </CardTitle>
          <hr />
          <CardFooter>
            <div className="author">
              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                <img
                  alt="..."
                  className="avatar img-raised mr-2"
                // src={require("assets/img/rihanna.jpg").default}
                />
                <span>Lord Alex</span>
              </a>
            </div>
            <div className="stats">
              <i className="fa fa-heart mr-1" />
              5.3k
            </div>
          </CardFooter>
        </CardBody>
      </Card>
    </Col>
  </Row>
</>)
export const Comment1 = () => (<>
  Comment1<br />
  <h3 className="text-center">Post your comment</h3>
  <Media className="media-post">
    <Form className="form">
      <a
        className="pull-left author"
        href="#pablo"
        onClick={(e) => e.preventDefault()}
      >
        <div className="avatar">
          <Media
            alt="..."
            object
            src={
              require("assets/img/faces/kaci-baum-2.jpg").default
            }
          />
        </div>
      </a>
      <Media body>
        <Row>
          <Col md="6">
            <FormGroup>
              <Input
                className="border-input"
                placeholder="Your Name"
                type="email"
              />
            </FormGroup>
          </Col>
          <Col md="6">
            <FormGroup>
              <Input
                className="border-input"
                placeholder="Your email"
                type="email"
              />
            </FormGroup>
          </Col>
        </Row>
        <Input
          className="border-input"
          placeholder="Write some nice stuff or nothing..."
          rows="6"
          type="textarea"
        />
        <div className="media-footer">
          <h6 className="text-muted">Sign in with</h6>
          <Button
            className="btn-just-icon btn-round mr-1"
            color="twitter"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            <i className="fa fa-twitter" />
          </Button>
          <Button
            className="btn-round btn-just-icon mr-1"
            color="facebook"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            <i className="fa fa-facebook" />
          </Button>
          <Button
            className="btn-just-icon btn-round"
            color="google"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            <i className="fa fa-google-plus" />
          </Button>
          <Button
            className="pull-right"
            color="info"
            href="#pablo"
            onClick={(e) => e.preventDefault()}
          >
            Post Comment
          </Button>
        </div>
      </Media>
      {/* end media-body */}
    </Form>
  </Media>
</>)

export const Section1 = ({ title, content }) => (<>
  {/* Section1<br />/ */}
  <div className="space-top" />
  {title ? <h2 className="title-uppercase">{title}</h2> : null}
  {title ? <br /> : null}
  <p>{content}</p>
</>)

export const Section2 = () => (<>
  Section2<br />
  <Row>
    <Col className="ml-auto mr-auto text-center" md="8">
      <h2 className="title">Is this what you are looking for?</h2>
      <h5 className="description">
        The more formalized version launching today brings a slate of
        corporate partners and a more structured application review
        process.
      </h5>
    </Col>
  </Row>
</>)
