

import useStyles from 'isomorphic-style-loader/useStyles';
import React from 'react';
import PropTypes from 'prop-types';
import withStyles from 'isomorphic-style-loader/withStyles';

// external-global styles must be imported in your JS.
import p from '../../../public/assets_front/css/bootstrap.min.css';
import q from '../../../public/assets_front/css/paper-kit.css';
import r from '../../../public/assets_front/demo/demo.css';
import s from '../../../public/assets_front/demo/react-demo.css';
// import u from '../../../public/assets_front/css/startup.css';
import ai from '../../../public/css/aichi-test.css';
// import sl from "slick-carousel/slick/slick.css";
// import slt from "slick-carousel/slick/slick-theme.css";
import rac from 'react-alice-carousel/lib/alice-carousel.css';

export default function FrontLayout({ children }) {
  useStyles(p, q, r, s, ai, rac);
  return <>{children}</>;
}

FrontLayout.propTypes = {
  children: PropTypes.node.isRequired,
};
