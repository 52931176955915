import React from 'react';
// import { Link } from "react-router-dom";
// JavaScript plugin that hides or shows a component based on your scroll
import Headroom from 'headroom.js';
// reactstrap components
import {
  Button,
  Collapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  NavLink,
  Container,
  UncontrolledTooltip,
} from 'reactstrap';
// core components

import Link from 'components/Link/Link';

function WhiteNavbar({ arr, menuSetting = {} }) {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  // React.useEffect(() => {
  //   const headroom = new Headroom(document.getElementById('navbar-main'));
  //   // initialise
  //   headroom.init();
  // });
  // console.log(arr)
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle('nav-open');
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        // className="fixed-top"
        expand="lg" id="navbar-main" color={menuSetting.middleBgColor || 'info'}>
        <Container>
          <div className="navbar-translate">
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle('nav-open');
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav
              className="ml-auto mr-auto"
              // className="ml-auto"
              navbar>
              {arr.map(({ href, title }, i) =>
                <NavItem key={`middlenav${i}`}>
                  <NavLink href={href}
                    style={{ fontSize: '16px' }}
                  >{title}</NavLink>
                </NavItem>
              )}
              {/* <NavItem>
                <NavLink href="/components/"><i className="nc-icon nc-cart-simple" /> Buy Now</NavLink>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default WhiteNavbar;
