import React from 'react';

import FrontLayout from 'components/FrontLayout/FrontLayout';
import OfficialLayout from 'components/FrontLayout/OfficialLayout';
import FrontLayoutAichi from 'components/FrontLayout/FrontLayoutAichi';
import Official from './Official';
import OfficialTest from './OfficialTest';
import AiasoOfficial from './AiasoOfficial'
import OfficialBoBee from './OfficialBoBee';
import OfficialCycu from './OfficialCycu';
import { MyDimmer } from 'layouts/Dimmer';
import CycuIndex from '../cycuFront/CycuIndex';

const title = '首頁';

function action(props) {
  // const hash = global.window && global.window.location.hash // 有含#
  // console.log(props)
  return {
    chunks: ['official'],
    title,
    component: (!(props.companyObj && props.companyObj.useOfficial) ? <><MyDimmer /></> :
      props.companyId === 'TcnPTNkcuN' ? // TcnPTNkcuN BoBee官網
        <FrontLayout>
          <OfficialBoBee {...props} />
        </FrontLayout> :
        props.companyId === 'Wuwrcczn0Z' ? // Wuwrcczn0Z 愛知測試
          <FrontLayoutAichi>
            <AiasoOfficial {...props} />
          </FrontLayoutAichi> :
          props.companyId === 'lu6226DYO8' || props.companyId === 'F2BByjzuro' ? // F2BByjzuro 資宸 lu6226DYO8 雲辦公
            <FrontLayout>
              <OfficialTest {...props} />
            </FrontLayout> :
            props.companyId === '9g7aBeSWwc' ? // 9g7aBeSWwc 中原
              <FrontLayout>
                <OfficialCycu {...props}>
                  <CycuIndex {...props} />
                </OfficialCycu>
              </FrontLayout> :
              <OfficialLayout {...props}>
                <Official {...props} />
              </OfficialLayout>
    ),
  };
}

export default action;
