import React from "react";
// react plugin used to create an image gallery
import { Gallery, Item } from "react-photoswipe-gallery";
// import 'photoswipe/dist/photoswipe.css'
// import 'photoswipe/dist/default-skin/default-skin.css'

function IwGallery({ title = '', arr = [] }) {
  return (<>
    {/* <div className="section section-blue javascript-components"> */}
    {/* <Container> */}
    {title ? <div className="title">
      <h3>{title}</h3>
      {/* <br /> */}
    </div> : null}
    {/* gallery */}
    {/* <div className="pswp-gallery"> */}
    <div className="pswp-thumbnails">
      <Gallery>
        {arr.map(({ title = '', x = '', y = '', imgPre = '', imgOri = '' }, key) => {
          return (<React.Fragment key={`pswp${key}`}>
            <Item
              title={title}
              original={imgOri}
              thumbnail={imgPre}
              width={x}
              height={y}
            >
              {({ ref, open }) => (
                <div className="pswp-thumbnail">
                  <div className="gallery-item">
                    <img
                      ref={ref}
                      onClick={open}
                      src={imgOri}
                      alt={title}
                      // className="vertical-image img-rounded img-responsive"
                      className="vertical-image img-responsive"
                      style={{ cursor: 'pointer' }}
                    />
                    <figcaption
                      className="gallery-caption"
                      itemProp="caption description"
                    >
                      {title}
                    </figcaption>
                  </div>
                </div>
              )}
            </Item>
          </React.Fragment>);
        })}
      </Gallery>
    </div>
  </>);
}

export default IwGallery;
